import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Button from './Button';
import { useSelector } from 'react-redux';
import { IStore } from '../types';
import { useConfig } from '../lib/hooks';

type Props = {
    next: () => void;
};

function OnboardingWelcome({ next }: Props) {
    let name = useSelector<IStore, string | undefined>((state) => state.website?.customerData?.firstname);
    let config = useConfig();
    const router = useRouter();
    useEffect(() => {
        // Always do navigations after the first render
        router.push('/?step=start', undefined, { shallow: true });
    }, []);
    return (
        <div className="flex justify-center items-center h-screen relative">
            {config.ui.logo && <img src={config.ui.logo.src} alt={config.ui.logo.alt} className="h-15 absolute top-0" />}
            <div className="text-center max-w-screen-md p-10">
                <h1 className="mb-7 text-6xl font-light">{name ? `Hallo ${name}` : 'Hallo!'}</h1>

                {config.product.name.indexOf('freenet', 0) !== -1 ? (
                    <>
                        <p className="opacity-75 leading-7 text-base pb-4">Klasse, dass Du Deine eigene Webseite erstellen möchtest.</p>
                        <p className="opacity-75 leading-7 text-base pb-4">
                            Die nächsten Schritte helfen Dir, bereits die ersten Inhalte<br />und eine für Dich passende Designvorlage zu wählen.
                        </p>
                        <p className="mb-15 opacity-75 leading-7 text-base">
                            Deine Wunschadresse (Domain), unter der Du dann im Internet erreichbar bist, wählst Du gesondert aus, wenn Du Deine Webseite veröffentlichen möchtest.
                        </p>
                    </>
                ) : (
                    <p className="mb-15 opacity-75 leading-7 text-base">
                        Nimm Dir 2 Minuten Zeit, um Deine Seite einzurichten. In diesem Abschnitt legst Du die grundlegenden Infos fest und kannst zum Schluss zwischen einer Vielzahl an passenden Designvorlagen wählen.
                    </p>
                )}

                {/* button id is set here for tracking purposes */}
                <Button id="start-on-boarding" variant="primary" size="large" onClick={next}>
                    Jetzt loslegen
                </Button>
            </div>
        </div>
    );
}

export default OnboardingWelcome;
