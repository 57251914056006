import Button from './Button';
import { CSSProperties, ReactNode } from 'react';
import IconArrowLeft from './icons/ArrowLeft';
import IconInfo from './icons/Info';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from './Tooltip';
import clsx from 'clsx';
import { IAction, IStore } from '../types';
import { Dispatch } from 'redux';
import { useConfig } from '../lib/hooks';

type Props = {
    children?: ReactNode;
    steps: number;
    size?: 'lg' | 'md';
    currentStep: number;
    back: () => void;
    next: () => void;
    headline: ReactNode;
    subheadline?: string;
    tip?: {
        headline: string;
        description: ReactNode;
    };
};

function OnboardingForm({ children, steps, currentStep, next, back, headline, subheadline, tip, size = 'md' }: Props) {
    let config = useConfig();
    let formIsValid = useSelector<IStore, boolean>((state) => state.onboarding.formIsValid);

    let dispatch = useDispatch<Dispatch<IAction>>();

    function onClickBack() {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: false
        });

        back();
    }

    function onClickNext() {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: false
        });

        next();
    }

    let nextHeadlineStyle: CSSProperties = {};

    if (config.ui.colors.onboardingStepHeadlineColor) {
        nextHeadlineStyle.color = config.ui.colors.onboardingStepHeadlineColor;
    }

    let tipStyle: CSSProperties = {};

    if (config.ui.colors.onboardingTipBackground) {
        tipStyle.backgroundColor = config.ui.colors.onboardingTipBackground;
    }

    return (
        <>
            <div className="flex h-screen flex-col">
                <div className="flex-grow flex items-center justify-center pt-20 pb-40">
                    <div className={clsx('container mx-auto', size === 'md' && 'max-w-180', size === 'lg' && 'max-w-200')}>
                        {steps > 1 && (
                            <h1 className="text-3xl font-light text-gray-500" style={nextHeadlineStyle}>
                                Schritt {currentStep}/{steps}
                            </h1>
                        )}
                        <h2 className="text-3xl font-light">{headline}</h2>
                        {steps > 1 && subheadline && <p className="mt-3 text-xs opacity-50">{subheadline}</p>}
                        {steps > 1 && tip && (
                            <div className="rounded bg-gray-200 px-4 py-3 flex items-center mt-5" style={tipStyle}>
                                <div className="flex-grow-0 mr-4">
                                    <div className="rounded-full bg-white w-6 h-6 flex items-center justify-center">
                                        <IconInfo className="w-3 h-3" />
                                    </div>
                                </div>
                                <div className="flex-grow">
                                    <span className="block text-sm font-semibold">{tip.headline}</span>
                                    <p>{tip.description}</p>
                                </div>
                            </div>
                        )}
                        <div className="mt-10">{children}</div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 border-t border-gray-300 bg-white">
                <div className={clsx('container mx-auto p-2 flex', steps > 1 ? 'justify-between' : 'justify-center')}>
                    {steps > 1 && (
                        <Button variant="text" size="large" onClick={onClickBack} className="opacity-75">
                            <IconArrowLeft className="mr-3" />
                            Zurück
                        </Button>
                    )}
                    <Tooltip label="Bitte wähle eine Vorlage aus" triggerOffset={10} isEnabled={!formIsValid}>
                        <Button
                            variant="primary"
                            size="large"
                            onClick={formIsValid ? onClickNext : undefined}
                            disabled={false === formIsValid}
                        >
                            Nächster Schritt
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </>
    );
}

export default OnboardingForm;
