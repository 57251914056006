import { useSelector, useDispatch } from 'react-redux';
import IconCheckmark from './icons/Checkmark';
import clsx from 'clsx';
import { IAction, IStore } from '../types';
import { IPartialContentCollection } from '@dogado/webcard-shared';
import { Dispatch } from 'redux';
import { CSSProperties } from 'react';
import { useConfig } from '../lib/hooks';

type Props = {
    title: string;
    type: keyof IPartialContentCollection;
};

function OnboardingContentFormCard({ title, type }: Props) {
    let config = useConfig();
    let isActive = useSelector<IStore, boolean>((state) => (type ? !!state.content[type]?.enabled : true));
    let dispatch = useDispatch<Dispatch<IAction>>();

    function toggle() {
        dispatch({
            type: 'TOGGLE_CONTENT',
            key: type
        });
    }

    let style: CSSProperties = {};

    if (isActive) {
        if (config.ui.colors.checkboxActiveBackground) {
            style.backgroundColor = config.ui.colors.checkboxActiveBackground;
            style.borderColor = config.ui.colors.checkboxActiveBackground;
        }

        if (config.ui.colors.checkboxActiveText) {
            style.color = config.ui.colors.checkboxActiveText;
        }
    }

    return (
        <div
            className={clsx(
                'border border-gray-400 rounded flex justify-between items-center px-4 py-6',
                type && 'cursor-pointer hover:bg-gray-100 transition-all duration-150 ease-in'
            )}
            onClick={type ? toggle : undefined}
        >
            <div className={clsx(type ? '' : 'text-gray-500')}>{title}</div>
            <div
                className={clsx(
                    'h-5 w-5 flex items-center justify-center rounded-full transition-all duration-150 ease-in text-primary-contrast',
                    isActive ? 'bg-primary' : 'border border-gray-400'
                )}
                style={style}
            >
                <IconCheckmark className={clsx('w-2 h-2 transition-all duration-150 ease-in', isActive ? 'opacity-100' : 'opacity-0')} />
            </div>
        </div>
    );
}

export default OnboardingContentFormCard;
