import OnboardingContentFormCard from './OnboardingContentFormCard';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { IAction } from '../types';
import { Dispatch } from 'redux';

function OnboardingContentForm() {
    let dispatch = useDispatch<Dispatch<IAction>>();
    const router = useRouter();
    useEffect(() => {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: true
        });
        router.push('/?step=content', undefined, { shallow: true });
    }, []);

    return (
        <>
            <h4 className="mb-3 text-xs font-semibold">Empfohlen</h4>
            <div className="grid grid-cols-3 grid-flow-row gap-3 mb-10">
                <OnboardingContentFormCard title="Kontakt" type="contact" />
                <OnboardingContentFormCard title="Anfahrt" type="directions" />
            </div>

            <h4 className="mb-3 text-xs font-semibold">Weitere Inhalte</h4>
            <div className="grid grid-cols-3 grid-flow-row gap-3">
                <OnboardingContentFormCard title="Bildergalerie" type="gallery" />
                <OnboardingContentFormCard title="Öffnungszeiten" type="openingHours" />
                <OnboardingContentFormCard title="Social Media" type="socialMedia" />
            </div>
        </>
    );
}

export default OnboardingContentForm;
