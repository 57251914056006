import { useRouter } from 'next/router';
import Select from './Select';
import { useSelector, useDispatch } from 'react-redux';
import { ChangeEvent, useEffect } from 'react';
import { IMediaCategory } from '@dogado/webcard-shared';
import { IAction, IStore } from '../types';
import { Dispatch } from 'redux';

function OnboardingIndustryForm() {
    let categories = useSelector<IStore, IMediaCategory[]>((state) =>
        state.mediaCategories.filter((category) => category.type === 'industry')
    );
    let industry = useSelector<IStore, number | undefined>((state) => state.website?.industry?.id);
    const router = useRouter();
    useEffect(() => {
        // Always do navigations after the first render
        router.push('/?step=branche', undefined, { shallow: true });
    }, []);
    let dispatch = useDispatch<Dispatch<IAction>>();

    function onChange(e: ChangeEvent<HTMLSelectElement>) {
        let selectedIndustry = categories.find((category) => category.id === parseInt(e.target.value));

        if (selectedIndustry) {
            dispatch({
                type: 'SET_INDUSTRY',
                industry: selectedIndustry
            });
        }
    }

    useEffect(() => {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: industry !== undefined
        });
    }, [industry]);

    return (
        <Select value={industry || 'empty'} onChange={onChange} placeholder="Branche">
            <option disabled value="empty">
                Bitte auswählen
            </option>
            {categories.map((category) => (
                <option key={category.id} value={category.id}>
                    {category.title}
                </option>
            ))}
        </Select>
    );
}

export default OnboardingIndustryForm;
