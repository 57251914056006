import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';

import { useMemo, useState } from 'react';
import { useRouter } from 'next/dist/client/router';

import OnboardingWelcome from '../components/OnboardingWelcome';
import OnboardingForm from '../components/OnboardingForm';
import OnboardingIndustryForm from '../components/OnboardingIndustryForm';
import OnboardingTitleForm from '../components/OnboardingTitleForm';
import OnboardingTagForm from '../components/OnboardingTagForm';
import OnboardingContentForm from '../components/OnboardingContentForm';
import OnboardingTemplateForm from '../components/OnboardingTemplateForm';
import OnboardingSetup from '../components/OnboardingSetup';
import LargeSpinner from '../components/LargeSpinner';
import { useServerState, useIsomorphicLayoutEffect, useConfig } from '../lib/hooks';
import Head from '../components/Head';
import Locked from '../components/Locked';
import { IStore } from '../types';

type StepProps = {
    back: () => void;
    next: () => void;
    steps: number;
    currentStep: number;
};

function Index() {
    let router = useRouter();
    let [stage, setStage] = useState(0);
    let isOnboardingSuccessful = useSelector<IStore, boolean | undefined>((state) => state.website?.properties?.onboardingSuccessful);
    let isLocked = useSelector<IStore, boolean>((state) => state.locked);
    let config = useConfig();

    let { isLoading } = useServerState();
    let [isRedirecting, setIsRedirecting] = useState(false);

    let stages = useMemo(() => {
        let result: (({ next }: StepProps) => JSX.Element)[] = [];

        if (config.onboarding.welcome.enabled) {
            result.push(({ next }: StepProps) => <OnboardingWelcome next={next} />);
        }

        if (config.onboarding.industry.enabled) {
            result.push((props: StepProps) => (
                <OnboardingForm
                    {...props}
                    headline={
                        <>
                            Der erste Schritt zu Deiner Webcard.
                            <br />
                            Wähle Deine Branche aus.
                        </>
                    }
                >
                    <OnboardingIndustryForm />
                </OnboardingForm>
            ));
        }

        if (config.onboarding.title.enabled) {
            result.push((props: StepProps) => (
                <OnboardingForm
                    {...props}
                    headline="Wie soll der Titel Deiner Webcard lauten?"
                    tip={{
                        headline: 'Tipp von uns',
                        description: 'Kombiniere Deinen Namen doch mit Deinem Beruf und Deinem Standort'
                    }}
                >
                    <OnboardingTitleForm />
                </OnboardingForm>
            ));
        }

        if (config.onboarding.tag.enabled) {
            result.push((props: StepProps) => (
                <OnboardingForm
                    {...props}
                    headline="Welche Top 3 Dienstleistungen / Fähigkeiten beschreiben Dich am besten?"
                    tip={{
                        headline: 'Tipp von uns',
                        description: (
                            <>
                                Starte doch einfach mit Deinem Beruf, einer Branche oder Interessen, die Dich beschreiben.
                                Beispielvorschläge: Beautyexperte, Fliesenleger, Kfz-Meister
                            </>
                        )
                    }}
                >
                    <OnboardingTagForm />
                </OnboardingForm>
            ));
        }

        if (config.onboarding.content.enabled) {
            result.push((props: StepProps) => (
                <OnboardingForm
                    {...props}
                    headline="Welche dieser Elemente möchtest Du auf Deiner Seite anzeigen?"
                    subheadline="Du kannst später noch Elemente löschen, ändern oder weitere hinzufügen."
                >
                    <OnboardingContentForm />
                </OnboardingForm>
            ));
        }

        if (config.onboarding.template.enabled) {
            result.push((props: StepProps) => (
                <OnboardingForm
                    {...props}
                    headline="Wähle eine passende Designvorlage aus."
                    subheadline="Keine Angst, Du kannst später noch die Designvorlage ändern und personalisieren."
                    size="lg"
                >
                    <OnboardingTemplateForm />
                </OnboardingForm>
            ));
        }

        result.push((props: StepProps) => <OnboardingSetup />);

        return result;
    }, [config]);

    useIsomorphicLayoutEffect(() => {
        if (false === isLoading && isOnboardingSuccessful && router.query.force !== '1') {
            setIsRedirecting(true);

            router.push('/editor');
        }
    }, [isLoading]);

    let isReady = false === isLoading && false === isRedirecting;

    function next() {
        setStage(stage + 1);
    }

    function back() {
        setStage(stage - 1);
    }

    const transitions = useTransition(false === isReady, null, {
        from: { opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        trail: 1000
    });

    if (isLocked) {
        return <Locked />;
    }

    return (
        <>
            <Head>
                <title>Einrichtung - Webcard</title>
            </Head>
            <div className="fixed top-0 left-0 right-0">
                <div
                    className="bg-primary h-1 transition-all duration-500 ease-out"
                    style={{ width: `${(100 / (stages.length - 1)) * stage}%` }}
                ></div>
            </div>
            {isReady &&
                stages[stage]({
                    currentStep: stage,
                    steps: stages.length - 1,
                    next,
                    back
                })}
            {transitions.map(
                ({ item, props, key }) =>
                    item && (
                        <animated.div style={props} key={key} className="z-50 fixed inset-0 bg-white flex justify-center items-center">
                            <LargeSpinner />
                        </animated.div>
                    )
            )}
        </>
    );
}

export default Index;
