import { useRouter } from 'next/router';
import clone from 'clone-deep';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useState, ChangeEvent, useEffect } from 'react';
import Combobox from './Combobox';
import Button from './Button';
import IconCheckmark from './icons/Checkmark';
import clsx from 'clsx';
import equal from 'fast-deep-equal';
import { IAction, IStore } from '../types';
import { IPartialTags } from '@dogado/webcard-shared';
import { Dispatch } from 'redux';

function OnboardingTagForm() {
    let storeTags = useSelector<IStore, IPartialTags>((state) => clone(state.tags));
    const router = useRouter();

    let initialStoreTags = useMemo(() => storeTags, []);
    let [tags, setTags] = useState(() => {
        let result = storeTags;

        for (let i = result.length; i < 3; i++) {
            result.push({
                title: ''
            });
        }

        return result;
    });

    useEffect(() => {
        if (storeTags) {
            let mappedTags = tags.map((tag) => {
                let storeTag = storeTags.find((t) => t.title === tag.title);

                return storeTag || tag;
            });

            if (false === equal(mappedTags, tags)) {
                setTags(mappedTags);
            }
        }
    }, [storeTags]);

    let dispatch = useDispatch<Dispatch<IAction>>();
    useEffect(() => {
        // Always do navigations after the first render
        router.push('/?step=skills', undefined, { shallow: true });
    }, []);
    function onAdd() {
        let newTags = [...tags];

        newTags.push({
            id: undefined,
            title: ''
        });

        setTags(newTags);
    }

    function change(key: number, title: string) {
        let newTags = tags.map((tag, k) => {
            if (key === k) {
                if (title !== tag.title) {
                    let initialStoreTag = initialStoreTags.find((t) => t.title === title.trim());

                    return (
                        initialStoreTag || {
                            id: undefined,
                            title: title
                        }
                    );
                }
            }

            return tag;
        });

        setTags(newTags);
    }

    function onChange(e: ChangeEvent<HTMLInputElement>, key: number) {
        change(key, e.target.value);
    }

    function onSelect(tag: { title: string }, key: number) {
        change(key, tag.title);
    }

    useEffect(() => {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: tags.filter((tag) => tag.title.trim().length > 0).length > 0
        });
    }, [tags]);

    function onBlur() {
        dispatch({
            type: 'SET_TAGS',
            tags: tags.filter((tag) => tag.title.trim().length > 0)
        });
    }

    return (
        <div className="grid grid-cols-2 grid-flow-row gap-6">
            {tags.map((tag, key) => {
                return (
                    <div className="p-5 border border-gray-400 rounded" key={key}>
                        <div className="flex justify-between items-center mb-4">
                            <div className="h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center font-semibold text-sm leading-none">
                                {key + 1}
                            </div>
                            <div
                                className={clsx(
                                    'w-7 h-7 rounded-full border border-green-100 flex items-center justify-center transition-all duration-150 ease-in',
                                    tag.title.length > 0 ? 'opacity-100' : 'opacity-0'
                                )}
                            >
                                <IconCheckmark className="text-green-100 w-3 h-3" />
                            </div>
                        </div>
                        <Combobox
                            selectedItems={tags}
                            onChange={(e) => onChange(e, key)}
                            onBlur={onBlur}
                            placeholder="Dienstleistung / Fähigkeit suchen"
                            onSelect={(tag) => onSelect(tag, key)}
                            defaultInputValue={tag.title}
                        />
                    </div>
                );
            })}
            <div className="p-5 border border-gray-400 rounded h-full flex items-center justify-center mb-5 border-dashed">
                <Button variant="secondary" onClick={onAdd} className="my-6">
                    Dienstleistung / Fähigkeit hinzufügen
                </Button>
            </div>
        </div>
    );
}

export default OnboardingTagForm;
