import Lottie from 'lottie-react-web';
import setupAnimation from '../data/lottie/setup.json';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/dist/client/router';
import { Dispatch } from 'redux';
import { IAction } from '../types';
import { changeColors } from '../lib/lottie';
import { useConfig } from '../lib/hooks';

function OnboardingTemplateForm() {
    let config = useConfig();
    let dispatch = useDispatch<Dispatch<IAction>>();
    let router = useRouter();

    useEffect(() => {
        dispatch({
            type: 'SET_PROPERTY',
            key: 'onboardingSuccessful',
            value: true
        });

        setTimeout(() => {
            router.push('/editor');
        }, 5000);
    }, []);

    let json = useMemo(() => changeColors(setupAnimation, config.ui.colors.primary), []);

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="container mx-auto max-w-180 text-center">
                <h1 className="text-3xl font-light">Einen Moment bitte, wir richten Dir gerade Deine Webcard ein.</h1>
                <Lottie
                    options={{
                        animationData: json,
                        loop: false
                    }}
                />
            </div>
        </div>
    );
}

export default OnboardingTemplateForm;
