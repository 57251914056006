import { animated, useSpring, config } from 'react-spring';
import clsx from 'clsx';
import { forwardRef, SelectHTMLAttributes, ReactNode, Ref } from 'react';

type SelectProps = { icon?: ReactNode } & SelectHTMLAttributes<HTMLSelectElement>;

function Select({ placeholder, className, value, children, icon = null, ...props }: SelectProps, ref: Ref<HTMLSelectElement>) {
    let transition = useSpring({
        transform: value ? 'translate3d(0, -20px, 0) scale(0.7)' : 'translate3d(0, 0px, 0) scale(1)',
        config: {
            ...config.stiff,
            clamp: true
        }
    });

    return (
        <div className={clsx('pt-5', className)}>
            <div className="relative">
                {icon && <div className="absolute pt-1">{icon}</div>}
                <select
                    className={clsx(
                        'border-b border-dark-20 w-full py-2 -mt-2 leading-none placeholder-dark-20 text-base focus:border-primary transition-all duration-150 outline-none focus:outline-none text-dark-100',
                        icon && 'pl-7'
                    )}
                    value={value}
                    id={placeholder}
                    ref={ref}
                    {...props}
                >
                    {children}
                </select>
                <animated.label
                    className={clsx(
                        'mt-1 top-0 left-0 absolute leading-none text-base text-dark-20 origin-top-left transition-colors duration-150',
                        !!value ? 'text-dark-50' : 'text-dark-20 pointer-events-none',
                        icon && 'ml-7'
                    )}
                    style={transition}
                    htmlFor={placeholder}
                >
                    {placeholder}
                </animated.label>
            </div>
        </div>
    );
}

export default forwardRef(Select);
