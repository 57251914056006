import { useRouter } from 'next/router';
import Input from './Input';
import { useSelector, useDispatch } from 'react-redux';
import { ChangeEvent, useEffect } from 'react';
import { IAction, IStore } from '../types';
import { Dispatch } from 'redux';

function OnboardingTitleForm() {
    let title = useSelector<IStore, string>((state) => {
        if (undefined !== state.templateData?.website?.title && null !== state.templateData?.website?.title) {
            return state.templateData?.website?.title;
        }

        return state.website?.title ?? '';
    });
    const router = useRouter();
    useEffect(() => {
        // Always do navigations after the first render
        router.push('/?step=title', undefined, { shallow: true });
    }, []);
    let dispatch = useDispatch<Dispatch<IAction>>();

    function handleChangeTitle(title: string) {
        dispatch({
            type: 'SET_TEMPLATE_DATA_TITLE',
            title
        });
    }

    function onBlur() {
        dispatch({
            type: 'SET_TITLE',
            title
        });
    }

    useEffect(() => {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: typeof title === 'string' && title.length > 0
        });
    }, [title]);

    return (
        <Input
            type="text"
            name="title"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeTitle(event.currentTarget.value)}
            onBlur={onBlur}
            value={title}
            autoComplete="off"
            placeholder="Titel Deiner Webseite"
        />
    );
}

export default OnboardingTitleForm;
