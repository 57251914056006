import { useRouter } from 'next/router';
import TemplatePreview from './TemplatePreview';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IAction, IStore } from '../types';
import { IGetTemplateResponse } from '@dogado/webcard-shared';
import { Dispatch } from 'redux';

function OnboardingTemplateForm() {
    let currentTemplate = useSelector<IStore, string | undefined | null>((state) => state.website?.template);
    let templates = useSelector<IStore, IGetTemplateResponse>((state) => state.templates || {});
    const router = useRouter();
    useEffect(() => {
        // Always do navigations after the first render
        router.push('/?step=design', undefined, { shallow: true });
    }, []);

    let dispatch = useDispatch<Dispatch<IAction>>();

    useEffect(() => {
        dispatch({
            type: 'SET_ONBOARDING_FORM_IS_VALID',
            valid: typeof currentTemplate === 'string' && currentTemplate.length > 0
        });
    }, [currentTemplate]);

    return (
        <div className="grid grid-cols-3 grid-flow-row gap-4 mb-10">
            {Object.keys(templates).map((template) => (
                <TemplatePreview key={template} name={template} />
            ))}
        </div>
    );
}

export default OnboardingTemplateForm;
