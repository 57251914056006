import clsx from 'clsx';
import Button from './Button';
import { useSelector, useDispatch, batch } from 'react-redux';
import IconCheckmark from './icons/Checkmark';
import { getTemplatePreviewImage } from '../lib/utils';
import { IAction, IStore } from '../types';
import { IGetTemplateResponse, IPartialTags } from '@dogado/webcard-shared';
import { Dispatch } from 'redux';
import { useStaticUrl } from '../lib/hooks';

type Props = {
    name: string;
};

function TemplatePreview({ name }: Props) {
    let currentTemplate = useSelector<IStore, string | undefined | null>((state) => state.website?.template);
    let primaryColor = useSelector<IStore, string | undefined | null>((state) => state.website?.primaryColor);
    let templates = useSelector<IStore, IGetTemplateResponse>((state) => state.templates || {});
    let template = currentTemplate && templates[currentTemplate];
    let defaultPrimaryColor = template && template.defaultPrimaryColor;
    let tags = useSelector<IStore, IPartialTags>((state) => state.tags);

    let isPrimaryColorModified = typeof defaultPrimaryColor === 'string' && primaryColor && defaultPrimaryColor !== primaryColor;
    let isCurrentTemplate = name === currentTemplate;

    let staticUrl = useStaticUrl();
    let dispatch = useDispatch<Dispatch<IAction>>();

    function onClick() {
        batch(() => {
            dispatch({
                type: 'SET_TEMPLATE',
                template: name
            });

            let defaultFont = templates[name].defaultFont;

            if (typeof defaultFont === 'string') {
                dispatch({
                    type: 'SET_FONT',
                    font: defaultFont
                });
            }

            let defaultPrimaryColor = templates[name].defaultPrimaryColor;

            if (typeof defaultPrimaryColor === 'string') {
                dispatch({
                    type: 'SET_PRIMARY_COLOR',
                    color: isPrimaryColorModified && primaryColor ? primaryColor : defaultPrimaryColor
                });
            }

            let defaultBackgroundColor = templates[name].defaultBackgroundColor;

            if (typeof defaultBackgroundColor === 'string') {
                dispatch({
                    type: 'SET_BACKGROUND_COLOR',
                    color: defaultBackgroundColor
                });
            }

            let defaultTags = templates[name].defaultTags;

            if (tags.length === 0 && Array.isArray(defaultTags)) {
                dispatch({
                    type: 'SET_TAGS',
                    tags: defaultTags.map((tag) => ({
                        title: tag
                    }))
                });
            }
        });
    }

    return (
        <div
            className={clsx(
                'rounded border overflow-hidden transition-all duration-150 ease-in',
                isCurrentTemplate ? 'border-primary' : 'border-gray-400'
            )}
        >
            <div
                className={clsx(
                    'h-4 border-b px-2 leading-none flex items-center transition-all duration-150 ease-in',
                    isCurrentTemplate ? 'border-primary bg-primary' : 'border-gray-400'
                )}
            >
                <span
                    className={clsx(
                        'font-extrabold tracking-widest text-lg transition-all duration-150 ease-in',
                        isCurrentTemplate ? 'text-white' : 'text-gray-400'
                    )}
                >
                    ···
                </span>
            </div>
            <div className="relative cursor-pointer" onClick={onClick}>
                {staticUrl && <img src={getTemplatePreviewImage(staticUrl, name)} className="w-full" alt={name} />}
                <div
                    className={clsx(
                        'absolute inset-0 opacity-0 transition-all duration-150 ease-in flex items-center justify-center p-5',
                        isCurrentTemplate ? 'bg-dark-50 opacity-100' : 'bg-dark-90 hover:opacity-100'
                    )}
                >
                    <Button variant={isCurrentTemplate ? 'primary' : 'outline-inverted'} className="block w-full justify-center py-1">
                        {isCurrentTemplate ? (
                            <>
                                <IconCheckmark className="mr-2" />
                                Ausgewählt
                            </>
                        ) : (
                            'Designvorlage auswählen'
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default TemplatePreview;
